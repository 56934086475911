@use '@styles/_partials' as *;
@use 'sass:math';

@mixin switchStyles($height, $width, $isRTL: false) {
  $borderWidth: 1px;
  border: $borderWidth solid $white;
  height: $height;
  width: $width;
  border-radius: math.div($height, 2);

  $circleOffset: 5px;
  $circleDiameter: $height - ($circleOffset * 2);

  &::before {
    content: '';
    position: absolute;
    @if $isRTL {
      right: $circleOffset - $borderWidth;
    } @else {
      left: $circleOffset - $borderWidth;
    }
    top: $circleOffset - $borderWidth;
    width: $circleDiameter;
    height: $circleDiameter;
    border-radius: math.div($circleDiameter, 2);
    background-color: $white;
    transition: background-color 0.2s, transform 0.2s ease-out,
      transform-origin 0.2s;
    @if $isRTL {
      transform-origin: right;
    } @else {
      transform-origin: left;
    }
  }

  &:hover::before {
    background-color: $primary;
  }

  $hoverScale: 1.2;
  &.isOn {
    &::before {
      @if $isRTL {
        transform: translateX(
          -($width - $circleDiameter - ($circleOffset * 2))
        );
        transform-origin: left;
      } @else {
        transform: translateX($width - $circleDiameter - ($circleOffset * 2));
        transform-origin: right;
      }
    }

    &:active::before {
      @if $isRTL {
        transform: translateX(
          -($width - ($circleDiameter * $hoverScale) - ($circleOffset * 2))
        );
      } @else {
        transform: translateX(
          $width - ($circleDiameter * $hoverScale) - ($circleOffset * 2)
        );
      }
    }
  }
}

@use '@styles/_partials' as *;
@use './Infographic.rtl.scss';
@use '@styles/components/Switch.scss' as *;
@use 'sass:math';

.chartSection {
  color: $black;
  min-height: 500px;
  text-align: center;
  padding: pxToRem(60px) 0;
  overflow: hidden;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    padding-top: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: all calc($animationDuration + $animationDurationAddend * 2) ease;
  }

  // Styles for Section background
  &.pistachiosBG {
    &:after {
      background-image: $greenGradient;
    }
  }

  &.almondsBG {
    &:after {
      background-image: $brownGradient;
    }

    .switchButton {
      &:hover {
        color: $orange;
      }
    }

    .chartList li {
      &:first-child .progress {
        background: $brown;
      }
    }
  }

  .innerContainer {
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  .title,
  .body,
  .chartData,
  .bulletData {
    position: relative;
    z-index: 1;
  }

  .title {
    font-family: $primaryFontHeavy;
    max-width: 460px;

    margin: pxToRem(10px) auto pxToRem(20px);
    text-transform: uppercase;
    @include font-size(28px);
    @include line-height(28);
    letter-spacing: 5.6px;

    @include respond-to($md) {
      @include font-size(30px);
      @include line-height(40);
      letter-spacing: 7.6px;
    }
    @include respond-to($lg) {
      max-width: 960px;
      @include font-size(48px);
      @include line-height(66);
      letter-spacing: 8px;
    }
  }

  .body {
    font-family: $primaryFontRegular;
    max-width: 300px;
    margin: pxToRem(10px) auto;

    @include font-size(16px);
    @include line-height(28);

    @include respond-to($lg) {
      max-width: $containerMaxWidth;
      @include font-size(24px);
      @include line-height(36);
    }
  }

  .chartContainer {
    position: relative;
    margin: auto;
    margin-top: pxToRem(40px);
    margin-bottom: pxToRem(60px);
    max-width: 100%;
    justify-content: center;
    flex-direction: column;

    @include respond-to($lg) {
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .chartSwiperWrapper {
      width: 100%;
      position: relative;
      overflow: visible;

      .swiperButtonNext,
      .swiperButtonPrev {
        color: black;
        display: block;
        position: absolute;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        border: 0;
        padding: 0;
        cursor: pointer;
        width: 30px;
        height: 30px;
        top: 50%;
      }

      .swiperButtonPrev {
        transform: rotate(180deg);
        left: 0;
      }
      .swiperButtonNext {
        right: 0;
      }

      :global(.swiper-pagination-bullets) {
        margin-top: pxToRem(20px);
      }

      :global(.swiper-pagination-bullet) {
        background-color: white;
        opacity: 1;
        width: 20px;
        height: 20px;
        transition: background-color 0.5s;
        margin-right: pxToRem(10px);

        &:last-child {
          margin-right: 0;
        }
      }

      :global(.swiper-pagination-bullet-active) {
        background-color: black;
      }

      p {
        max-width: 280px;
        margin: auto;
      }
      @include respond-to($lg) {
        display: none;
      }

      .noHeight {
        height: 0;
        overflow: hidden;
        display: block;
      }
    }

    .swiper {
      width: 100%;
      position: static;

      @include respond-to($lg) {
        display: none;
      }

      .chartSwiperData {
        .chartWrapper {
          display: flex;
          flex-direction: column;
          width: 100%;

          &:focus,
          &:focus-visible {
            outline: none;
          }

          h3 {
            @include font-size(22px);
            @include line-height(28);
          }

          .h3 {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
              @include font-size(22px);
              max-width: 300px;
              margin-left: auto;
              margin-right: auto;
            }
          }

          p {
            @include font-size(16px);
            @include line-height(28);
          }

          .bulletData {
            ul {
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
    }

    .chartData {
      display: none;
      flex-direction: column;
      flex-basis: 36%;

      @include respond-to($lg) {
        display: flex;
        align-items: center;
        & > * {
          max-width: 350px;
          width: 100%;
        }
      }

      .chartSubtitle {
        margin-bottom: pxToRem(20px);
      }
    }

    .bulletData {
      ul {
        list-style-type: disc;
        margin-top: pxToRem(45px);
        margin-left: pxToRem(30px);
        margin-right: 0;

        li {
          display: list-item;
          text-align: left;
          margin-bottom: pxToRem(23px);
          padding-left: pxToRem(20px);

          p {
            min-height: 0;
            display: inline;
          }
        }
      }
    }

    ul,
    hr,
    small {
      margin: auto;
      width: 100%;
      max-width: 240px;

      @include respond-to($lg) {
        max-width: none;
      }
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include respond-to($lg) {
        align-items: center;
        flex-direction: row;
      }

      & > span {
        display: block;
        text-align: left;

        @include respond-to($lg) {
          flex-basis: 112px;
          margin-right: pxToRem(15px);
          margin-left: none;
          text-align: right;
        }
      }

      div {
        width: 100%;
        @include respond-to($lg) {
          width: auto;
          flex-grow: 1;
        }
      }
      .progress {
        height: 34px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-radius: 17px;
        background: $white;
        padding-right: pxToRem(15px);
        @include font-size(16px);
        color: rgba($black, 1);

        transition: transform 0.75s (calc(var(--transition-delay) + 1.45s)),
          opacity 0.5s (calc(var(--transition-delay) + 1.55s)),
          color 0.5s (calc(var(--transition-delay) + 1.95s));
      }

      &:first-child {
        label,
        .progress {
          font-family: $primaryFontBold;
        }

        .progress {
          background: rgb(50, 105, 18);
          color: rgba($white, 1);
        }
      }
    }

    .h3 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        @include h3();
        @include line-height(28);
        color: $black;
        letter-spacing: normal;
        margin-top: 0;
        margin-bottom: pxToRem(15px);
        text-transform: uppercase;
      }
    }

    h3,
    small {
      margin-bottom: pxToRem(15px);
    }

    h3 {
      text-transform: uppercase;
    }

    small {
      p {
        min-height: auto;
      }
    }

    .chartList {
      margin: pxToRem(20px) auto pxToRem(40px);
      width: 100%;

      @include respond-to($lg) {
        margin: pxToRem(25px) auto;
      }

      hr {
        margin-top: pxToRem(30px);
        margin-bottom: pxToRem(5px);
      }

      small {
        @include font-size(14px);
        @include line-height(18);
        text-align: left;
      }

      li {
        margin-bottom: pxToRem(10px);
        @include respond-to($lg) {
          margin-bottom: pxToRem(15px);
        }
      }

      li {
        width: 100%;
      }
    }

    .chartImage {
      align-self: center;
      max-width: 150px;
      margin-bottom: pxToRem(40px);

      @include respond-to($lg) {
        flex-basis: 28%;
        max-width: none;
        margin-bottom: 0;
      }

      &:after {
        background-image: radial-gradient(
          ellipse 100% 100% at 50% 50%,
          rgba($white, 0.6) 0%,
          transparent 50%
        );
        height: 1450px;
        width: 1450px;
        top: calc((100% - 1450px) / 2);
        left: calc((100% - 1450px) / 2);
        position: absolute;
        z-index: -1;
        content: '';
      }

      img {
        z-index: 5;
        width: 90%;
        height: fit-content;
        height: auto;
        margin: auto;
      }

      .shadow {
        width: 100%;
        height: 15px;
        opacity: 0.25;
        margin: auto;
        content: '';
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        background-image: radial-gradient(
          closest-side at 50% 50%,
          rgba($black, 0.75) 0%,
          rgba($primary, 0.1) 100%
        );
      }
    }
  }

  .footnoteWrapper {
    text-align: center;

    .footnote {
      display: inline-block;
      text-align: left;
      @include font-size(12px);
      @include line-height(20);
      z-index: 1;
      position: relative;
    }
  }
}

// Styles for Switch
.switchContainer {
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.desktopSwitchRow {
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: center;
  align-items: center;
  padding-bottom: pxToRem(30px);

  button {
    margin: 0 pxToRem(12px);
  }

  p {
    font-weight: bold;
    @include font-size(18px);
    @include line-height(25);
    transition: color 0.2s;
  }
}

.switchButton {
  background: transparent;
  border: none;
  font-size: inherit;
  color: inherit;
  font-family: $primaryFontBold;
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}

.whiteText {
  color: $white;
}

.desktopSwitchLabel {
  display: none;
  @include respond-to($lg) {
    display: block;
  }

  padding-top: pxToRem(30px);
  padding-bottom: pxToRem(30px);

  @include font-size(22px);
  @include line-height(28);
  text-align: center;
  white-space: pre-line;
}

.switch {
  position: relative;
  cursor: pointer;
  background-color: transparent;
  @include switchStyles(40px, 75px);

  @include respond-to($lg) {
    @include switchStyles(44px, 85px);
  }

  &:hover::before {
    background-color: $primary;
  }

  &.isOn {
    &:hover::before {
      background-color: $orange;
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .chartSection {
    &:after {
      border-radius: 100%;
      transform: scale(0);
      filter: grayscale(100%);
    }

    .title,
    .body,
    .chartImage,
    .chartData,
    .chartBody,
    .chartSwiperWrapper,
    .bulletData,
    .footnote,
    .desktopSwitchRow,
    li {
      opacity: 0;
    }

    .chartList li {
      .progress {
        opacity: 0;
        transform: scaleX(0);
        transform-origin: 0;
        color: rgba($black, 0);
      }

      &:first-child .progress {
        color: rgba($white, 0) !important;
      }
    }

    .shadow {
      transform: scale(0);
    }
  }

  .isInViewport {
    // bgkd gradient
    &:after {
      transform: scale(1);
      border-radius: 0;
      filter: grayscale(0%);
    }

    // Variables for Infographic animation delays
    $delayAddend: 0.1s;

    $switchDelay: $animationDelay;
    $dataDelay: $switchDelay + 0.5s;
    $chartBodyDelay: $switchDelay + 1.5s;

    .desktopSwitchRow {
      animation: revealItem forwards $animationDuration $switchDelay;
    }

    .title {
      display: block;
      animation: revealItem forwards $animationDuration
        calc($switchDelay + $delayAddend);
    }

    .body {
      display: block;
      animation: revealItem forwards $animationDuration
        calc($switchDelay + $delayAddend * 2);
    }

    .chartImage {
      animation: revealItem forwards $animationDuration
        calc($switchDelay + $delayAddend * 3);

      .shadow {
        animation: scaleToFull forwards
          calc($animationDuration + $animationDurationAddend) $dataDelay;
      }
    }

    .chartData,
    .bulletData {
      animation: revealItem forwards $animationDuration $dataDelay;
    }

    .chartList li {
      .progress {
        transform: scaleX(1);
        opacity: 1;
        color: rgba($black, 1);
      }

      &:first-child .progress {
        color: rgba($white, 1) !important;
      }
    }

    .chartSwiperWrapper {
      animation: revealItem forwards $animationDuration $dataDelay;
    }

    .chartBody {
      animation: revealItem forwards $animationDuration $chartBodyDelay;
    }

    .footnote {
      animation: revealItem forwards $animationDuration
        calc($chartBodyDelay + $delayAddend);
    }

    li {
      &:nth-of-type(1) {
        animation: revealItem forwards $animationDuration
          calc($dataDelay + $delayAddend);
      }

      &:nth-of-type(2) {
        animation: revealItem forwards $animationDuration
          calc($dataDelay + $delayAddend * 2);
      }

      &:nth-of-type(3) {
        animation: revealItem forwards $animationDuration
          calc($dataDelay + $delayAddend * 3);
      }

      &:nth-of-type(4) {
        animation: revealItem forwards $animationDuration
          calc($dataDelay + $delayAddend * 4);
      }
    }
  }
}

@use '@styles/_partials' as *;
@use '@styles/components/Switch.scss' as *;

:global(html[dir='rtl']) .chartSection {
  .switch {
    @include switchStyles(40px, 75px, true);

    @include respond-to($lg) {
      @include switchStyles(44px, 85px, true);
    }

    &:hover::before {
      background-color: $primary;
    }

    &.isOn {
      &:hover::before {
        background-color: $orange;
      }
    }
  }

  .chartContainer {
    li {
      & > span {
        text-align: right;

        @include respond-to($lg) {
          text-align: left;
          margin-right: initial;
          margin-left: pxToRem(15px);
        }
      }
    }

    .progress {
      padding-left: pxToRem(15px);
      padding-right: initial;
    }

    .bulletData {
      ul {
        margin-left: initial;
        margin-right: pxToRem(30px);

        li {
          text-align: right;
          padding-left: initial;
          padding-right: pxToRem(20px);
        }
      }
    }

    .chartList {
      small {
        text-align: right;
      }
    }

    .chartSwiperWrapper {
      :global(.swiper-pagination-bullet) {
        margin-left: pxToRem(10px);
        margin-right: initial;

        &:last-child {
          margin-left: 0;
          margin-right: initial;
        }
      }
    }
  }

  .footnote {
    text-align: right;
  }
}

@media (prefers-reduced-motion: no-preference) {
  :global(html[dir='rtl']) .isInViewport {
    .chartList li {
      .progress {
        transform-origin: right;
      }
    }
  }
}
